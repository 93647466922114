import { z } from "zod";
import ruleset from "@/_helpers/ruleset";

export interface ISupplementForm {
    name: string;
    brand: string;
    category: string[];
    priority: string;
    unit: string;
    link?: string;
    notes?: string;
}

export const supplementFormSchema = z.object({
    name: ruleset.required,
    brand: ruleset.required,
    priority: ruleset.select,
    category: ruleset.multiselect,
    unit: ruleset.select,
    link: ruleset.linkOptional,
    notes: ruleset.stringOptional,
});

  


