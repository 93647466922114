import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Services and interfaces
import { IProspectConvertForm, prospectConvertSchema } from "@/interfaces/prospect/prospect_convert_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import ConvertForm from "./convert_form";
import Modal from "@/components/modal/modal";


interface _ConvertModalProps {
    open: boolean;
    onClose: () => void;
    name: string;
    isLoading: boolean;
    onSubmit: (data: IProspectConvertForm) => void;
}

const ConvertModal: FC<_ConvertModalProps> = ({
    open,
    onClose,
    name,
    isLoading,
    onSubmit
}) => {

    const { t } = useTranslation();

    const formMethods = useForm<IProspectConvertForm>({
        resolver: zodResolver(prospectConvertSchema),
        mode: 'onBlur',
        defaultValues: {
            payment_amount: '',
            payment_schedule: '',
            checkin_template_id: '',
            checkin_days: [1],
        }
    });
    
    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : handleClose}
            title={t('modals.convertProspect.title')}
            text={t('modals.convertProspect.text', {name: name})}
            children={
                <FormProvider {...formMethods}>
                    <ConvertForm />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.convert'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: formMethods.handleSubmit(onSubmit)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '500px'}}}
            />
    )
}

export default ConvertModal;