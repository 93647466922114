import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { INutritionBuilder, IUnit } from "@/interfaces/nutrition/nutrition_builder";

// Components
import SelectInput from "../select_input";


interface _UnitInputProps {
    dayIndex: number;
    mealIndex: number;
    foodIndex: number;
    units: IUnit[];
}

const UnitInput: FC<_UnitInputProps> = ({ 
    dayIndex, 
    mealIndex, 
    foodIndex,
    units,
}) => {

    const { control, setValue } = useFormContext<INutritionBuilder>();
    const food = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}` });

    const handleUnitChange = (value: IUnit) => {
        
        const macros = food.macros;
        const protein = roundNumber(macros.protein / 100 * (food.portion * value.weight), 1);
        const carbs = roundNumber(macros.carbs / 100 * (food.portion * value.weight), 1);
        const fat = roundNumber(macros.fat / 100 * (food.portion * value.weight), 1);
        const fibre = roundNumber(macros.fibre / 100 * (food.portion * value.weight), 1);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.protein`, protein);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.carbs`, carbs);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.fat`, fat);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.fibre`, fibre);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.calories`, (protein + carbs) * 4 + fat * 9);
    };

    
    return (
        <SelectInput
            name={`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.unit`}
            control={control}
            items={units}
            itemLabel="label"
            itemValue="weight"
            itemKey="label"
            returnObject
            hideErrorMessage
            gutter="0"
            onChange={handleUnitChange}
            />
    )
}

export default UnitInput;