import { z } from "zod";
import ruleset, { transformEmptyStringToNull } from "@/_helpers/ruleset";

export interface IProspectConvertForm {
    payment_amount: number|string,
    payment_schedule: string,
    checkin_template_id: string,
    checkin_days: number[],
}

export const prospectConvertSchema = z.object({
    payment_amount: transformEmptyStringToNull(ruleset.numberOptional),
    payment_schedule: ruleset.selectOptional,
    checkin_template_id: ruleset.required,
    checkin_days: ruleset.checkinDays
});