import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";
import { formatFileSize } from "@/_helpers/number_functions";

// Services and interfaces
import { IFolder } from "@/interfaces/folder/folder";

// Components
import FolderMenu from "./folder_menu";
import { ColDef } from "@/components/datatable";


const useFolderTableHeaders = (): ColDef<IFolder>[] => {

    const { t } = useTranslation();

    const headers: ColDef<IFolder>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row: IFolder) => capitalizeWords(row.name)
        },
        {
            field: 'updated_at',
            headerName: t('components.dataTable.headers.lastUpdated'),
            width: '15%',
            render: (row: IFolder) => new Date(row.updated_at).toLocaleDateString()
        },
        {
            field: 'file_count',
            headerName: t('components.dataTable.headers.content'),
            width: '15%',
            render: (row: IFolder) => t('components.vault.folders.file', { count: row.file_count })
        },
        {
            field: 'size',
            headerName: t('components.dataTable.headers.size'),
            width: '20%',
            render: (row: IFolder) => formatFileSize(row.size)
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IFolder) => <FolderMenu row={row} />
        }
    ];

    return headers;
}

export default useFolderTableHeaders;