import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

// Interfaces
import { IMealFood, INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import UnitInput from "./unit_field";
import PortionInput from "./portion_field";
import { ColDef } from "@/components/datatable";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";


interface _FoodHeadersProps {
    dayIndex: number;
    mealIndex: number;
    onRemoveFood: (index: number) => void;
}

const useFoodHeaders = ({ 
    dayIndex,
    mealIndex,
    onRemoveFood,
}: _FoodHeadersProps): ColDef<IMealFood>[] => {

    const { t } = useTranslation();
    const { control } = useFormContext<INutritionBuilder>();

    const handleDeleteFood = (index: number) => {
        onRemoveFood(index);
    }

    const headers: ColDef<IMealFood>[] = [
        {
            field: 'name',
            headerName: t('components.nutritionBuilder.headers.food'),
            width: '20%',
            render: (_, i) =>
                <TextInput
                    name={`plan.${dayIndex}.meals.${mealIndex}.foods.${i}.name`}
                    control={control}
                    gutter="0"
                    hideErrorMessage
                    />
        },
        {
            field: 'portion',
            headerName: t('components.nutritionBuilder.headers.portion'),
            width: '7.5%',
            render: (_, i) => 
                <PortionInput 
                    dayIndex={dayIndex}
                    mealIndex={mealIndex}
                    foodIndex={i}
                    />
        },
        {
            field: 'unit',
            headerName: t('components.nutritionBuilder.headers.unit'),
            width: '10%',
            render: (row, i) => 
                <UnitInput 
                    dayIndex={dayIndex}
                    mealIndex={mealIndex}
                    foodIndex={i}
                    units={row.units}
                    />
        },
        {
            field: 'protein',
            headerName: t('components.nutritionBuilder.headers.protein'),
            width: '10%',
            render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} foodIndex={i} macro="protein" />
        },
        {
            field: 'carbs',
            headerName: t('components.nutritionBuilder.headers.carbs'),
            width: '10%',
            render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} foodIndex={i} macro="carbs" />
        },
        {
            field: 'fat',
            headerName: t('components.nutritionBuilder.headers.fat'),
            width: '10%',
            render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} foodIndex={i} macro="fat" />
        },
        {
            field: 'fibre',
            headerName: t('components.nutritionBuilder.headers.fibre'),
            width: '10%',
            render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} foodIndex={i} macro="fibre" />
        },
        {
            field: 'calories',
            headerName: t('components.nutritionBuilder.headers.calories'),
            width: '10%',
            render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} foodIndex={i} macro="calories" />
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (_, i) => 
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        kind="danger-ghost"
                        size="small"
                        icon={<TrashCan />}
                        onClick={() => handleDeleteFood(i)}
                        />
                </Box>
        }
    ];

    return headers;
}

export default useFoodHeaders;


interface _FoodMacroProps {
    dayIndex: number;
    mealIndex: number;
    foodIndex: number;
    macro: 'protein' | 'carbs' | 'fat' | 'fibre' | 'calories';
}

const FoodMacro: FC<_FoodMacroProps> = ({
    dayIndex,
    mealIndex,
    foodIndex,
    macro
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<INutritionBuilder>();
    const value = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.${macro}` });

    return (
        macro !== 'calories' ? 
            <span className="body-02-compact">{`${Number(value).toLocaleString(undefined, {maximumFractionDigits: 1})} g`}</span> :
            <span className="body-02-compact">{`${Number(value).toLocaleString(undefined, {maximumFractionDigits: 1})} ${t('components.nutritionBuilder.macros.kcal')}`}</span>
    );
    
}