import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Components
import NumberInput from "@/components/number_input";


interface _PortionInputProps {
    dayIndex: number;
    mealIndex: number;
    foodIndex: number;
}

const PortionInput: FC<_PortionInputProps> = ({ 
    dayIndex, 
    mealIndex, 
    foodIndex,
}) => {

    const { control, setValue } = useFormContext<INutritionBuilder>();
    const foods = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.foods` });
    const food = useWatch({ control, name: `plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}` });

    useEffect(() => {
        const p = roundNumber(foods.reduce((acc, food) => acc + food.protein, 0), 1);
        const c = roundNumber(foods.reduce((acc, food) => acc + food.carbs, 0), 1);
        const f = roundNumber(foods.reduce((acc, food) => acc + food.fat, 0), 1);
        const fi = roundNumber(foods.reduce((acc, food) => acc + food.fibre, 0), 1);

        setValue(`plan.${dayIndex}.meals.${mealIndex}.protein`, p);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.carbs`, c);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.fat`, f);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.fibre`, fi);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.calories`, (p + c) * 4 + (f * 9));
    }, [foods]);

    const handlePortionChange = (value: number) => {
        const macros = food.macros;
        const protein = roundNumber(macros.protein / 100 * (value * food.unit.weight), 1);
        const carbs = roundNumber(macros.carbs / 100 * (value * food.unit.weight), 1);
        const fat = roundNumber(macros.fat / 100 * (value * food.unit.weight), 1);
        const fibre = roundNumber(macros.fibre / 100 * (value * food.unit.weight), 1);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.protein`, protein);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.carbs`, carbs);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.fat`, fat);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.fibre`, fibre);
        setValue(`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.calories`, (protein + carbs) * 4 + fat * 9);
    };

    
    return (
        <NumberInput
            name={`plan.${dayIndex}.meals.${mealIndex}.foods.${foodIndex}.portion`}
            control={control}
            maxLength={3}
            decimalPlaces={1}
            placeHolder="0"
            hideErrorMessage
            gutter="0"
            onChange={handlePortionChange}
            />
    )
}

export default PortionInput;