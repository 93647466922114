import baseApi from '@/repositories/base_api';

// Interfaces
import { IAPIResponse } from '@/interfaces/api/response';
import { IUserResponse } from '@/interfaces/user/user_response';
import { ILoginCredentials } from '@/interfaces/auth/login_credentials';

import * as Sentry from "@sentry/react";

export const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCsrfToken: build.query<void, void>({
            query: () => ({
              url: 'sanctum/csrf-cookie',
              method: 'GET',
              baseUrl: import.meta.env.VITE_BASE_URL,
            }),
          }),
          login: build.mutation<Promise<void>, ILoginCredentials>({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                data: credentials,
            }),
          }),
          logout: build.mutation<Promise<void>, void>({
            query: () => ({ url: 'auth/logout', method: 'POST' }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
              queryFulfilled.then((response) => 
                Sentry.setUser(null)).catch(() => {})
            },
          }),
          forgotPassword: build.mutation<Promise<void>, string>({
            query: (email) => ({
                url: 'auth/forgot-password',
                method: 'POST',
                data: { email },
            }),
          }),
          verifyEmail: build.mutation<Promise<void>, string>({
            query: (url) => ({
                url,
                method: 'POST',
            }),
          }),
          resendVerificationEmail: build.mutation<Promise<void>, number>({
            query: (id) => ({
                url: `verification/${id}/resend`,
                method: 'POST',
            }),
          }),
          getUser: build.query<IUserResponse, void>({
            query: () => ({ url: 'auth/user', method: 'GET' }),
            transformResponse: (response: IAPIResponse<IUserResponse>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
              queryFulfilled.then((response) => Sentry.setUser({
                id: response.data.user.id ?? undefined,
                email: response.data.user.email,
              })).catch(() => {})
            },
          }),
    }),
})

export const { 
    useGetCsrfTokenQuery,
    useLoginMutation, 
    useLogoutMutation, 
    useForgotPasswordMutation, 
    useVerifyEmailMutation, 
    useResendVerificationEmailMutation, 
    useGetUserQuery,
    useLazyGetUserQuery,
} = authApi;

// Passing undefined to the select function as queryArg requires a value
// const selectSubscriptionResult = subscriptionApi.endpoints.getSubscription.select(undefined);

// export const selectSubscriptionData = createSelector(
//   selectSubscriptionResult,
//   (subscriptionResult) => subscriptionResult.data
// );

// export const selectIsSubscribed = createSelector(
//   selectSubscriptionData,
//   (subscription) => subscription?.status === 'active'
// );

