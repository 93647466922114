import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Constants
import { supplementUnitOptions, supplementPriorityOptions } from "@/_constants/supplement";

// Interfaces
import { ISupplementBuilder, ISupplement } from "@/interfaces/supplement_plan/supplement_builder";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import Tag from "@/components/tag";
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";
import IconButton from "@/components//icon_button";
import SelectInput from "@/components/select_input";
import NumberInput from "@/components//number_input";


interface _SupplementHeadersProps {
    sectionIndex: number;
    onRemoveSupplement: (index: number) => void;
}

const useSupplementHeaders = ({ 
    sectionIndex,
    onRemoveSupplement
}: _SupplementHeadersProps): ColDef<ISupplement>[] => {

    const { t } = useTranslation();
    const { control } = useFormContext<ISupplementBuilder>();
    const sections = useWatch({ control, name: 'sections' });
    const disableDelete = sections?.length === 1 && sections[sectionIndex].supplements?.length === 1;

    const headers: ColDef<ISupplement>[] = [
        {
            field: 'name',
            headerName: t('components.supplementBuilder.headers.supplementName'),
            width: '25%',
            render: (row) => 
                <Box display="flex" minWidth="125px">
                    <span className="body-02-compact TwoLine--ellipsis">{capitalize(row.name)}</span>
                </Box>
        },
        {
            field: 'amount',
            headerName: t('components.supplementBuilder.headers.amount'),
            width: '10%',
            render: (_, i) => 
                <NumberInput
                    name={`sections.${sectionIndex}.supplements.${i}.amount`}
                    control={control}
                    decimalPlaces={1}
                    maxLength={5}
                    gutter="0"
                    hideErrorMessage
                    />
        },
        {
            field: 'unit',
            headerName: t('components.supplementBuilder.headers.unit'),
            width: '15%',
            render: (_, i) => 
                <SelectInput
                    name={`sections.${sectionIndex}.supplements.${i}.unit`}
                    control={control}
                    items={supplementUnitOptions}
                    itemKey="value"
                    gutter="0"
                    minWidth={false}
                    />
           
        },
        {
            field: 'priority',
            headerName: t('components.supplementBuilder.headers.priority'),
            width: '15%',
            render: (_, i) => 
                <SelectInput
                    name={`sections.${sectionIndex}.supplements.${i}.priority`}
                    control={control}
                    items={supplementPriorityOptions}
                    itemKey="value"
                    gutter="0"
                    minWidth={false}
                    />
        },
        {
            field: 'category',
            headerName: t('components.supplementBuilder.headers.category'),
            width: '25%',
            render: (row) => 
                <Box display="flex" alignItems="center">
                    {row.category?.map((cat, i) => 
                        <Tag key={i} index={i} 
                            label={t(`enums.supplementCategory.${cat}`)}
                            sx={{marginRight: row.category.length-1 !== i ? '4px' : 'unset'}}
                            />)}
                </Box>
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (_, i) => 
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        kind="danger-ghost"
                        size="small"
                        icon={<TrashCan />}
                        disabled={disableDelete}
                        onClick={() => onRemoveSupplement(i)}
                        />
                </Box>
        }
    ];

    return headers;
}

export default useSupplementHeaders;