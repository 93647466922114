import { FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { useSelector } from "react-redux";
import { ApiError } from "@/interfaces/api/error";
import { selectIsTrial } from "@/store/subscription";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import { useCancelSubscriptionMutation } from "@/repositories/subscription";
import { ISubscriptionCancellationForm, subscriptionCancellationFormSchema } from "@/interfaces/subscription_cancellation/subscription_cancellation_form";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import CancelSubscriptionForm from "./cancel_subscription_form";


interface _CancelSubscriptionModalProps {
    open: boolean;
    onClose: () => void;
}

const CancelSubscriptionModal: FC<_CancelSubscriptionModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();
    const isTrial = useSelector(selectIsTrial);
    const { data: invoice } = useGetUpcomingInvoiceQuery();
    const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
    const nextInvoiceDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';

    const formMethods = useForm<ISubscriptionCancellationForm>({
        resolver: zodResolver(subscriptionCancellationFormSchema),
        mode: 'onBlur',
        defaultValues: {
            reason: '',
            comments: ''
        }
    });

    const submit = (data: ISubscriptionCancellationForm) => {
        cancelSubscription(data).unwrap().then(() => {
            showToast({type: 'success', title: t(`notifications.billing.${isTrial ? 'trialCancelled' : 'subscriptionCancelled'}`)})
            formMethods.reset();
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t(`modals.${isTrial ? 'cancelTrial' : 'cancelSubscription'}.title`)}
            text={<Trans
                i18nKey={`modals.${isTrial ? 'cancelTrial' : 'cancelSubscription'}.text`}
                components={{ strong: <strong /> }}
                values={{ date: nextInvoiceDate }}
            />}
            children={<FormProvider {...formMethods}>
                <CancelSubscriptionForm />
            </FormProvider>}
            action1={{
                kind: "danger-ghost",
                label: t(`components.buttons.${isTrial ? 'confirmCancelTrial' : 'confirmCancelSubscription'}`),
                icon: <ArrowRight />,
                onClick: formMethods.handleSubmit(submit),
                loading: isLoading,
            }}

            cancel={{
                label: t(`components.buttons.${isTrial ? 'keepTrial' : 'keepSubscription'}`),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '540px', background: 'var(--background)'}}}
            />
    )
}

export default CancelSubscriptionModal;