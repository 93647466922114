import { t } from "i18next";


export const formatDuration = (durationInSeconds: number, showHours?: boolean): string => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
  
    if (hours > 0 || showHours) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
};

export const formatDateTime = (datetime: string): string => {
  const date = new Date(datetime);

  // Extract the date parts using local time
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Extract the time parts using local time
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return t('timeDate.textOutputs.dateAtTime', { date: `${day}/${month}/${year}`, time: `${hours}:${minutes}` });
}

export const formatDurationText = (seconds: number) => {
  if (seconds < 60) {
      return t('timeDate.seconds', { count: seconds });
  } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      // const remainingSeconds = seconds % 60;
      return t('timeDate.minute', { count: minutes });
      // return `${minutes} minute${minutes !== 1 ? 's' : ''}${remainingSeconds ? ` ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}` : ''}`;
  } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${t('timeDate.hour', { count: hours })} ${minutes ? t('timeDate.minute', { count: minutes }) : ''}`;
      return `${hours} hour${hours !== 1 ? 's' : ''}${minutes ? ` ${minutes} minute${minutes !== 1 ? 's' : ''}` : ''}`;
  }
};