import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

// Constants
import { checkinDayOptions, checkinFrequencyOptions, paymentScheduleOptions } from "@/_constants/client";

// Services and interfaces
import { useGetCheckinTemplatesQuery } from "@/repositories/checkin_template";

// Components
import Grid from "@mui/material/Grid";
import TextInput from "@/components/text_input";
import EmailInput from "@/components/email_input";
import PhoneInput from "@/components/phone_input";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";
import MultiselectInput from "@/components/multiselect_input";
import { InlineNotification } from "@/components/notification/notification";


interface ClientFormProps {
    isOnboarded?: boolean;
    isVerified?: boolean;
    isConfirmed?: boolean;
    isReadonly?: boolean;
    onConfirm: () => void;
}

const ClientForm: FC<ClientFormProps> = ({
    isOnboarded = false,
    isVerified = false,
    isConfirmed = false,
    isReadonly = false,
    onConfirm
}) => {

    const { t } = useTranslation();
    const { control, formState, watch, getValues, setValue } = useFormContext();
    const showEmailNotification = isOnboarded && !isVerified && formState.defaultValues?.email !== getValues('email');

    const { data: checkinTemplates } = useGetCheckinTemplatesQuery();
    const defaultTemplate = checkinTemplates?.find(c => c.default);

    const checkinFrequency = watch('checkin_frequency');
    const checkinDisabled = checkinFrequency === 'none';

    useEffect(() => {
        if (getValues('checkin_template_id') === '' && defaultTemplate) {
            setValue('checkin_template_id', defaultTemplate.id);
        }
    }, [defaultTemplate, getValues, setValue]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextInput
                    control={control}
                    name='first_name'
                    label={t('inputs.titles.firstName')}
                    placeHolder="Ben"
                    disabled={isOnboarded || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name='last_name'
                    control={control}
                    label={t('inputs.titles.lastName')}
                    placeHolder="Smith"
                    disabled={isOnboarded || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <EmailInput
                    name='email'
                    control={control}
                    label={t('inputs.titles.emailAddress')}
                    placeHolder="ben@domain.com"
                    disabled={isVerified || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <PhoneInput
                    name='mobile'
                    control={control}
                    label={t('inputs.titles.phoneNumber')}
                    placeHolder="+44 1234 567890"
                    disabled={isOnboarded || isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberInput
                    name='payment_amount'
                    control={control}
                    label={t('inputs.titles.paymentAmount')}
                    placeHolder="100"
                    decimalPlaces={2}
                    disabled={isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    name='payment_schedule'
                    control={control}
                    label={t('inputs.titles.paymentSchedule')}
                    items={paymentScheduleOptions}
                    allowEmpty
                    disabled={isReadonly}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    name='checkin_frequency'
                    control={control}
                    label={t('inputs.titles.checkinFrequency')}
                    items={checkinFrequencyOptions}
                    disabled={isReadonly}
                    onChange={() => setValue('checkin_days', [])}
                    />
            </Grid>
            <Grid item xs={6}>
                {checkinFrequency === 'weekly' ? 
                    <MultiselectInput
                        name='checkin_days'
                        control={control}
                        label={t('inputs.titles.checkinDay')}
                        items={checkinDayOptions}
                        disabled={isReadonly || checkinDisabled}
                        /> :
                    <SelectInput
                        name='checkin_days'
                        control={control}
                        label={t('inputs.titles.checkinDay')}
                        items={checkinDayOptions}
                        disabled={isReadonly || checkinDisabled}
                        />}
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    name='checkin_template_id'
                    control={control}
                    label={t('inputs.titles.checkinTemplate')}
                    items={checkinTemplates?.filter((c) => !c.draft) ?? []}
                    itemLabel="name"
                    itemValue="id"
                    disabled={isReadonly || checkinDisabled}
                    />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name='notes'
                    control={control}
                    size='textarea'
                    label={t('inputs.titles.notes')}
                    placeHolder={t('inputs.placeholders.clientNotes')}
                    maxLength={2000}
                    minRows={5}
                    disabled={isReadonly}
                    />
            </Grid>
            {showEmailNotification && <Grid item xs={12}>
                <InlineNotification
                    type={isConfirmed ? 'success' : 'info'}
                    title={t('inlineNotifications.client.emailChanged.title')}
                    message={<Trans
                        i18nKey="inlineNotifications.client.emailChanged.message"
                        components={{ strong: <strong /> }}
                        values={{ old: formState.defaultValues?.email, new: getValues('email') }}
                    />}
                    action={{
                        label: t('components.buttons.confirm'),
                        disabled: isConfirmed,
                        onClick: () => isConfirmed ? undefined : onConfirm(),
                    }}
                    />
            </Grid>}

        </Grid>
    );
}

export default ClientForm;