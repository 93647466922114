import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { IRegistrationForm } from '@/interfaces/registration/registration_form';


export const registrationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
      validate: build.mutation<Promise<void>, {email: string, access_code: string}>({
          query: ({email, access_code}) => ({ url: '/registration/validate', method: 'post', data: { email, access_code } }),
      }),
      register: build.mutation<{user_id: number}, IRegistrationForm>({
          query: (form) => ({ url: '/registration', method: 'post', data: form }),
          transformResponse: (response: IAPIResponse<{user_id: number}>) => response.data,
      }),
  }),
})

export const { useValidateMutation, useRegisterMutation } = registrationApi;