import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { IFolder } from "@/interfaces/folder/folder";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import FileModal from "@/pages/vault/files/file_modal";


interface _NoItemsProps {
    message?: string;
    folder: IFolder;
    disabled?: boolean;
}

const NoItems: FC<_NoItemsProps> = ({
    message = t('components.dataTable.noData.noVaultItemsFound'),
    folder,
    disabled = false
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addFile')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                    />
                    {open === 'add' && <FileModal
                        open={open === 'add'}
                        folder={folder!}
                        onClose={() => setOpen(null)}
                        />}
            </Box>

        </Box>
    )
}

export default NoItems;