import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ISubscriptionResponse } from '@/interfaces/subscription/subscription_response'
import { ISubscriptionCancellationForm } from '@/interfaces/subscription_cancellation/subscription_cancellation_form';


export const subscriptionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSubscription: build.query<ISubscriptionResponse, void>({
            query: () => ({ url: '/subscription', method: 'get' }),
            transformResponse: (response: IAPIResponse<ISubscriptionResponse>) => response.data,
            providesTags: ['Subscription']
        }),
        createSubscription: build.mutation<ISubscriptionResponse, string>({
            query: (payment_method_id: string) => ({ url: '/subscription', method: 'post', data: { payment_method_id: payment_method_id}}),
            transformResponse: (response: IAPIResponse<ISubscriptionResponse>) => response.data,
        }),
        cancelSubscription: build.mutation<ISubscriptionResponse, ISubscriptionCancellationForm>({
            query: (data) => ({ url: '/subscription/cancel', method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ISubscriptionResponse>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        }),
        renewSubscription: build.mutation<ISubscriptionResponse, void>({
            query: () => ({ url: '/subscription/renew', method: 'post' }),
            transformResponse: (response: IAPIResponse<ISubscriptionResponse>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        })
    }),
})

export const { 
    useGetSubscriptionQuery, 
    useCreateSubscriptionMutation, 
    useCancelSubscriptionMutation, 
    useRenewSubscriptionMutation 
} = subscriptionApi;

