
import { v4 as uuidv4 } from 'uuid';
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { IFood } from '@/interfaces/food/food';
import { IMealFood } from '@/interfaces/nutrition/nutrition_builder';
import { useAddRecentFoodMutation } from '@/repositories/food_recent';

// Styles
import { Add } from "@carbon/icons-react";

// Components
import FoodTable from './food_table';
import Modal from "@/components/modal/modal";
import { ApiError } from '@/interfaces/api/error';


interface _AddModalProps {
    open: boolean;
    onClose: () => void;
    selectedFoods?: string[];
    onAdd: (exercises: IMealFood[]) => void;
}

const AddModal: FC<_AddModalProps> = ({
    open,
    onClose,
    selectedFoods,
    onAdd,
}) => {

    const { t } = useTranslation();
    const [selected, setSelected] = useState<any[]>([]);
    const [addFoods, { isLoading: isAddingFoods }] = useAddRecentFoodMutation();

    const mapFoods = (foods: IFood[]): IMealFood[] => {
        return foods.map((f) => {
            return {
                uuid: uuidv4(),
                _id: f.id,
                name: f.name,
                protein: f.macros.protein,
                carbs: f.macros.carbs,
                fat: f.macros.fat,
                fibre: f.macros.fibre,
                calories: f.macros.calories,
                portion: 100,
                macros: {
                    calories: f.macros.calories,
                    protein: f.macros.protein,
                    carbs: f.macros.carbs,
                    fat: f.macros.fat,
                    fibre: f.macros.fibre,
                },
                unit: f.unit,
                units: f.units,
            }
        })
    }

    const handleSelectFood = (food: IFood) => {
        setSelected([...selected, food]);
    }

    const handleDeselectFood = (food: IFood) => {
        setSelected(selected.filter((e) => e.id !== food.id));
    }

    const handleAdd = () => {
        if (!selected || !onAdd) return;
        addFoods({foods: selected}).unwrap().then(() => {
            // Convert each IExercise to IExercise and pass it to onAdd
            const foods: IMealFood[] = mapFoods(selected);
            onAdd(foods);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={selected.length > 0 ? undefined : onClose}
            title={t('modals.nutritionFood')}
            children={<FoodTable selectedFoods={selectedFoods} onSelect={handleSelectFood} onDeselect={handleDeselectFood} />}
            action1={{
                kind: 'primary',
                label: t('components.buttons.add'),
                icon: <Add />,
                disabled: !selected.length,
                loading: isAddingFoods,
                onClick: handleAdd
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            sx={{'.MuiPaper-root': {width: '100%' ,maxWidth: '1400px'}}}
            />
    )
}

export default AddModal;