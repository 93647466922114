import { v4 as uuidv4 } from 'uuid';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from "react-i18next";
import { BaseSyntheticEvent, FC, useEffect, useState } from "react";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { FieldErrors, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";

// Styles
import { Add, DragVertical, WarningFilled } from "@carbon/icons-react";

// Services and interfaces
import { INutritionBuilder, IDay } from '@/interfaces/nutrition/nutrition_builder';

// Components
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";
import OverflowMenu from '@/components/overflow_menu';
import useSort from '@/components/sortable/sort_hooks';
import DeleteModal from '@/components/builder/delete_modal';
import RenameModal from '@/components/builder/rename_modal';


interface _DayMenuProps {
    selectedIndex: number;
    onSelectDay: (sectionIndex: number) => void;
    isLoading?: boolean;
}

const DayMenu: FC<_DayMenuProps> = ({
    selectedIndex,
    onSelectDay,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<INutritionBuilder>();
    const { fields, append, remove, move } = useFieldArray({
        control,
        name: 'plan'
    });

    const [selectedSection, setSelectedSection] = useState<string>(fields[0]?.id);
    const { sensors, restrictToVerticalAxis } = useSort();

    const newDay: IDay = {
        uuid: uuidv4(),
        name: '',
        protein: 0,
        carbs: 0,
        fat: 0,
        fibre: 0,
        calories: 0,
        meals: []
    }

    const handleSelectDay = (e: BaseSyntheticEvent, id: string) => {
        if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
        setSelectedSection(id);
        onSelectDay(fields.findIndex(item => item.id === id));
    }
    
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
    
        if (over !== null && active.id !== over.id) {
            const oldIndex = fields.findIndex(item => item.id === active.id);
            const newIndex = fields.findIndex(item => item.id === over.id);

            const selectedSectionIndex = fields.findIndex(item => item.id === selectedSection);
    
            let selectedSectionIndexAfter = selectedSectionIndex;
    
            // Check whether the currently selected section has moved
            if (selectedSectionIndex === oldIndex) {
                selectedSectionIndexAfter = newIndex;
            } else if (selectedSectionIndex > oldIndex && selectedSectionIndex <= newIndex) {
                selectedSectionIndexAfter = selectedSectionIndex - 1;
            } else if (selectedSectionIndex < oldIndex && selectedSectionIndex >= newIndex) {
                selectedSectionIndexAfter = selectedSectionIndex + 1;
            }
    
            move(oldIndex, newIndex);
            onSelectDay(selectedSectionIndexAfter);
        }
    };

    const handleRemoveDay = (dayIndex: number) => {
        if (selectedIndex === dayIndex && selectedIndex > 0) {
            onSelectDay(selectedIndex - 1);
        } else {
            onSelectDay(0);
        }
        remove(dayIndex);
    }

    useEffect(() => {
        const i = fields.findIndex(item => item.id === selectedSection);
        // If the selected section has been removed, select the first section
        if (i == -1) {
            setSelectedSection(fields[0].id);
        }
    }, [fields, selectedSection]);

    return (
        <Box className="Builder__menu">

            <Box className="Builder__menu-header">
                <span className="heading-06-compact">{t('components.nutritionBuilder.menu.title')}</span>
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addDay')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={isLoading}
                    onClick={() => append(newDay)}
                    />
            </Box>


            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <SortableContext items={fields.map(field => field.id)} strategy={verticalListSortingStrategy}>
                    
                    <Box className="Builder__menu-sections">
                        {isLoading ? <Skeleton variant="rounded" width="100%" height={48} /> :
                            fields.map((day, i) => (
                                <_MenuSection
                                    key={day.id}
                                    id={day.id}
                                    dayIndex={i}
                                    selectedDay={selectedIndex}
                                    handleSelect={(e) => handleSelectDay(e, day.id)}
                                    onCopy={(day) => append(day)}
                                    onRemove={fields.length > 1 ? handleRemoveDay : undefined}
                                    />
                            ))}
                        {!isLoading && <Box className="Builder__menu-indicator" style={{ top: `${selectedIndex * 48 + 16}px` }} />}
                    </Box>
                    
                </SortableContext>
            </DndContext>

        </Box>
    )
}

export default DayMenu;

interface _MenuDayProps {
    id: string
    dayIndex: number;
    selectedDay: number;
    handleSelect: (e: BaseSyntheticEvent) => void;
    onCopy: (workout: IDay) => void;
    onRemove?: (index: number) => void;
    isLoading?: boolean;
}

const _MenuSection: FC<_MenuDayProps> = ({
    id,
    dayIndex,
    selectedDay,
    handleSelect,
    onCopy,
    onRemove,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const { control, setValue, formState: { errors } } = useFormContext<INutritionBuilder>();

    const plan = useWatch({ control, name: 'plan' });
    const disabled = plan.length === 1;
    const day = useWatch({ control, name: `plan.${dayIndex}` });
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const dragStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const setErrorMessage = (errors: FieldErrors<INutritionBuilder>) => {
        const formErrors = errors.plan?.[dayIndex];
        if (formErrors?.name) return formErrors.name.message;
        if (formErrors?.meals?.message) return formErrors.meals.message;
        return t('components.nutritionBuilder.menu.sectionErrors');
    }

    const handleCopyDay = () => {
        const trimCount = day.name.length + 7 - 40;
        const regex = new RegExp(`.{${trimCount}}$`);
        const name = `${day.name.length > 33 ? day.name.replace(regex, '') : day.name} - ${t('general.copy')}`
        const newDay = {
            ...day,
            uuid: uuidv4(),
            name: name,
        }
        onCopy(newDay);
    }

    return (
        <Box 
            key={dayIndex}
            ref={setNodeRef}
            style={dragStyle}
            className={`Builder__menu-section ${selectedDay === dayIndex ? 'Builder__menu-section--selected' : ''}`} 
            onClick={handleSelect}
            >

            <Box width="8px" />

            {/* Title */}
            {isLoading ? <Skeleton variant="text" width={200} height={28} /> :
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                
                    <Box display="flex" alignItems="center" maxWidth="200px">
                        {errors.plan?.[dayIndex] && <WarningFilled style={{color: 'var(--support-error)', marginRight: '8px'}} />}
                        <span className="body-02-compact" style={{textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            {day.name ? day.name : t('components.nutritionBuilder.menu.dayPlaceholder')}</span>
                    </Box>
                    {errors.plan?.[dayIndex] && <span className="label-text-02" style={{color: 'var(--support-error)'}}>
                        {setErrorMessage(errors)}</span>}
                </Box>}

            {/* Spacer */}
            <Box flexGrow={1} padding="0 8px" />

            <Box className="Builder__menu-section-actions">
                {!disabled && <Box {...attributes} {...listeners} display="flex" alignItems="center" width="32px" justifyContent="center" sx={{cursor: 'grab'}} >
                    <DragVertical onClick={(e) => e.stopPropagation()} />
                </Box>}

                <OverflowMenu
                    options={[
                        {name: t('components.menuItems.rename'), action: () => setOpen('rename')},
                        {name: t('components.menuItems.copy'), action: handleCopyDay},
                    ]}
                    disableDelete={!onRemove}
                    onDelete={() => setOpen('delete')}
                    />
                {open === 'rename' && 
                    <RenameModal 
                        open={open === 'rename'} 
                        onClose={() => setOpen(null)}
                        onSubmit={(d) => setValue(`plan.${dayIndex}.name`, d.name, { shouldDirty: true, shouldValidate: true })}
                        title={t('modals.renameSection')}
                        name={day.name}
                        placeholder={t('components.nutritionBuilder.menu.dayPlaceholder')}
                        />}
                {open === 'delete' && 
                    <DeleteModal 
                        open={open === 'delete'} 
                        onClose={() => setOpen(null)}
                        title={t('modals.deleteDay.title')}
                        text={t('modals.deleteDay.text')}
                        onDelete={() => onRemove?.(dayIndex)}
                    />}
            </Box>

    </Box>
    )
}